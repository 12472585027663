import React, { Component } from 'react'

import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  async componentDidMount () {
    Auth.currentAuthenticatedUser().then(user => {
      console.log('currentAuthenticatedUser', user)
    }).catch(() => console.log('Not signed in'))
  }

  render () {

    return (
      <div>
        Dashboard
      </div>
    )
  }
}

export default withAuthenticator(Dashboard)
