import React from 'react'

import Amplify, { Auth } from 'aws-amplify'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

import awsconfig from '../aws/awsconfig'
import awsauth from '../aws/awsauth'

Amplify.configure(awsconfig)
Auth.configure({ oauth: awsauth })

const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState()
    const [user, setUser] = React.useState()

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState)
            setUser(authData)
        })
    }, [])

    return authState === AuthState.SignedIn && user ? (
        <div className="App">
            <div data-collapse="medium" data-animation="over-left" data-duration="400" data-doc-height="1" data-no-scroll="1" class="navbar w-nav"><a href="index.html" class="c-menu_logo w-nav-brand w--current"><img src="/Churnly-Logo-Colour.png" width="193" alt="Churnly logo" class="header_logo"></img></a>
            <nav role="navigation" class="nav-menu w-nav-menu"><a href="product.html" class="nav-link">Hello, {user.attributes.email}</a><AmplifySignOut /></nav>
            </div>
        </div>
        ) : (
        <div></div>
    )
}

export default AuthStateApp
