import React, { Component } from 'react'
import {
  Redirect,
  withRouter
} from 'react-router-dom'

class SigninCallback extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  async componentDidMount () {
    this.props.history.go('/')
  }

  render () {
    return (
      <Redirect to='/' />
    )
  }
}

export default withRouter(SigninCallback)
