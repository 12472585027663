import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard'
import SigninCallback from './pages/SigninCallback/SigninCallback'
import Header from './fragments/header'

export default class App extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  async componentDidMount () {
  }

  render () {
    return (
      <div className='App'>
        <Header />

        <Router>
          <Switch>

            <Route path='/signin-callback'>
              <SigninCallback />
            </Route>

            <PrivateRoute
              exact
              path='/signout'
              component={Dashboard}
            />

            <PrivateRoute
              exact
              path='/'
              component={Dashboard}
            />

          </Switch>
        </Router>

      </div>
    )
  }
}

/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
const PrivateRoute = ({ component, ...options }) => {
  return <Route {...options} component={Dashboard} />
}
